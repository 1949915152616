
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/equipmenttest";

@Options({})
export default class extends Vue {
  private count = {
    alarmNum: 0,
    alarmMonthNum: 0,
  };
  private refreshing = false;
  private list: any[] = [];

  mounted() {
    this.countNum();
    this.tree();
  }

  countNum() {
    api
      .count(this.$store.state.project.projectinfoDetail.id)
      .then((res: any) => {
        if (res.code === 0) {
          this.count.alarmNum = res.data.alarmNum;
          this.count.alarmMonthNum = res.data.alarmMonthNum;
        }
      });
  }
  tree() {
    api
      .tree(this.$store.state.project.projectinfoDetail.id)
      .then((res: any) => {
        if (res.code === 0) {
          this.list = res.data;
        }
      });
  }
  lbDetail(item: any) {
    this.$router.push({ path: "/app/lbfhdetail" });
    this.$store.commit("project/set_lbfhdetail", item);
  }
  onRefresh() {
    this.list = [];
    setTimeout(() => {
      this.refreshing = false;
      this.tree();
    }, 1000);
  }
}
