import request from "@/utils/request";

export default {
  // 环境监测器设备列表
  mobileenvdevicelist(args: any) {
    return request({
      url: "/environment/mobile/env/device/list",
      method: "get",
      params: args,
    });
  },
  // 环境信息列表
  mobileenvitemlist(args: any) {
    return request({
      url: "/environment/mobile/env/item/list",
      method: "get",
      params: args,
    });
  },
  // 环境监测指标查看
  envrulegetEnvRule(args: any) {
    return request({
      url: "/environment/env/rule/getEnvRule",
      method: "get",
      params: args,
    });
  },
};
