
import { Options, Vue, Watch } from "vue-property-decorator";
import info from "./info.vue";
import zuzhijiagou from "@/views/app/projectInfo/zuzhijiagou.vue";
import mapInfo from "./map.vue";
import * as api from "@/api/projectinfo.ts";
import comInfo from "./infos.vue";
import comVideo from "./video.vue";
import comPerson from "./person.vue";
import comWork from "./work.vue";
import safedanger from "./safedanger/index.vue";
import equipmenttest from "./ equipmenttest/index.vue";
import highformwork from "./highformwork/index.vue";
import deepexcavation from "./ deepexcavation/index.vue";

@Options({
  name: "ProjectInfo",
  components: {
    info,
    zuzhijiagou,
    mapInfo,
    comInfo,
    comVideo,
    comPerson,
    comWork,
    safedanger,
    equipmenttest,
    highformwork,
    deepexcavation,
  },
})
export default class extends Vue {
  private active = 0;

  private projectId = "";
  private reqdata = [];

  protected goBack() {
    this.$router.go(-1);
  }

  @Watch("$route", { immediate: true })
  private routerChange(route: any) {
    this.projectId = route.query && route.query.id;
    if (route.path === "/app/projectInfo") {
      api
        .mobileprojectdetail({
          projectId: this.projectId,
        })
        .then((res) => {
          this.reqdata = res.data;
        });
    }
  }
}
