
import { Options, Vue, Watch } from "vue-property-decorator";
import api from "@/api/projectinfo-jianchadan";

@Options({})
export default class extends Vue {
  private danhao = "";
  private recordslist: any[] = [];
  private refreshing = false;
  private loading = false;
  private finished = false;

  @Watch("danhao")
  onPersonNameChanged(newValue: string, oldValue: string) {
    this.refreshing = true;
    this.onRefresh();
  }

  protected onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.recordslist = [];
        this.refreshing = false;
      }
      api
        .page({
          pageNum: parseInt(this.recordslist.length / 10 + "") + 1,
          pageSize: 10,
          projectId: this.$store.state.project.projectinfoDetail.id,
          billNo: this.danhao,
        })
        .then((res: any) => {
          this.recordslist = [...this.recordslist, ...res.data.records];
          this.loading = false;
          if (this.recordslist.length >= res.data.total) {
            this.finished = true;
          }
        });
    }, 500);
  }

  protected onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }
}
