<template>
  <div v-if="reqdata">
    <van-image class="projectimg" :src="reqdata.project.showFileUrl">
      <template v-slot:error>加载失败</template>
    </van-image>
    <van-collapse v-model="activeNames">
      <van-collapse-item title="项目描述" name="1">
        {{ reqdata.project.detail }}
      </van-collapse-item>
    </van-collapse>
    <van-cell-group>
      <van-cell title="项目名称" :value="reqdata.project.name" />
      <van-cell
        title="建设单位"
        :value="reqdata.project.constructionUnitName"
      />
      <van-cell title="代建单位" :value="reqdata.project.daijianUnitName" />
      <van-cell title="勘察单位" :value="reqdata.project.kanchaUnitName" />
      <van-cell title="设计单位" :value="reqdata.project.designUnitName" />
      <van-cell title="监理单位" :value="reqdata.project.supervisorUnitName" />
      <van-cell title="施工总承包" :value="reqdata.project.generalUnitName" />
      <van-cell title="建筑面积" :value="reqdata.project.area" />
      <van-cell title="工程造价" :value="reqdata.project.amount" />
      <van-cell title="开工日期" :value="reqdata.project.startDate" />
      <van-cell title="计划竣工日期" :value="reqdata.project.endDate" />
    </van-cell-group>
  </div>
</template>

<script>
import { Vue } from "vue-property-decorator";
import * as api from "@/api/projectinfo.ts";

export default class extends Vue {
  activeNames = [];
  reqdata = null;
  mounted() {
    this.getreqdata(this.$store.state.project.projectinfoDetail.id);
  }
  getreqdata(arg) {
    api
      .mobileprojectdetail({
        projectId: arg,
      })
      .then((res) => {
        this.reqdata = res.data;
      });
  }
}
</script>

<style scoped>
.projectimg {
  width: 100%;
  height: 150px;
}
</style>
