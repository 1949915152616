import request from "@/utils/request";

export default {
  // 获取深基坑监测状况
  getPointCount(id: any) {
    return request({
      url: "project/pitRecord/getPointCount/" + id,
      method: "get",
    });
  },
  // 深基坑实时告警统计
  getRecordGroup(data: any) {
    return request({
      url: "project/pitRecord/getRecordGroup",
      method: "post",
      data,
    });
  },
  // 获取基坑列表
  getList(id: any) {
    return request({
      url: "project/pitProgram/getList/" + id,
      method: "get",
    });
  },
  // 深基坑告警统计
  getAlarmCountByDays(data: any) {
    return request({
      url: "project/pitRecord/getAlarmCountByDays",
      method: "post",
      data,
    });
  },
  //tab1数据
  getOneNewList(data: any) {
    return request({
      url: "project/pitDisplace/getNewList",
      method: "post",
      data,
    });
  },
  //tab2数据
  getTwoNewList(data: any) {
    return request({
      url: "project/pitDisplaceSlope/getNewList",
      method: "post",
      data,
    });
  },
  //tab3数据
  getThreeNewList(data: any) {
    return request({
      url: "project/pitSubsideBuild/getNewList",
      method: "post",
      data,
    });
  },
  //tab4数据
  getFourNewList(data: any) {
    return request({
      url: "project/pitSubsidePipeline/getNewList",
      method: "post",
      data,
    });
  },
  //tab5数据
  getFiveNewList(data: any) {
    return request({
      url: "project/pitSubsidePillars/getNewList",
      method: "post",
      data,
    });
  },
  //tab6数据
  getSixNewList(data: any) {
    return request({
      url: "project/pitWaterLevel/getNewList",
      method: "post",
      data,
    });
  },
  //tab7数据
  getSevenNewList(data: any) {
    return request({
      url: "project/pitSoilMass/getNewList",
      method: "post",
      data,
    });
  },
};
