
import { Options, Vue } from "vue-property-decorator";
import shengjiangji from "./shengjiangji/index.vue";
import qizhongji from "./qizhongji/index.vue";
import xlpingtai from "./xlpingtai/index.vue";
import lbfanghu from "./lbfanghu/index.vue";

@Options({
  components: {
    shengjiangji,
    qizhongji,
    xlpingtai,
    lbfanghu,
  },
})
export default class extends Vue {
  private tabArray: any[] = [
    "施工升降机",
    "塔式起重机",
    "卸料平台",
    "临边防护",
  ];
  private active = 0;

  changeTab(index: any) {
    this.active = index;
  }
}
