
import { Options, Vue } from "vue-property-decorator";
import anquanjianchadan from "./anquanjianchadan.vue";
import suishoupai from "./suishoupai.vue";
import tongjifenxi from "./tongjifenxi.vue";
import yidongxunjian from "./yidongxunjian.vue";

@Options({
  components: {
    anquanjianchadan,
    suishoupai,
    tongjifenxi,
    yidongxunjian,
  },
})
export default class extends Vue {
  private tabArray: any[] = ["统计分析", "安全检查单", "移动巡检", "随手拍"];
  private active = 0;

  changeTab(index: any) {
    this.active = index;
  }
}
