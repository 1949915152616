
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/deepexcavation";

@Options({})
export default class extends Vue {
  private programTotal = "";
  private pointTotal = "";
  private alarmNum = "";
  private warnNum = "";
  private flag = true;
  private popId = "";
  private show = false;
  private recordList: any[] = [];
  private recordList1: any[] = [];
  private recordGroup: any[] = [];
  private pointType = [
    "桩顶位移",
    "坡顶位移",
    "周边建筑沉降",
    "周边管线沉降",
    "立柱沉降",
    "水位",
    "土体深层水平位移",
  ];
  private resultPickerscolumns: any[] = [];
  private selectList: any[] = [];
  private tabArray: any[] = [
    "桩顶位移",
    "坡顶位移",
    "周边建筑沉降",
    "周边管线沉降",
    "立柱沉降",
    "水位检测",
    "土体深层水平位移",
  ];
  private myChart1 = null;
  private myChart2 = null;
  private active = 0;
  private resultPicker = false;

  mounted() {
    this.getPointCount();
    this.getSelectList();
  }

  openPopup() {
    this.resultPicker = true;
  }
  changeTab(index: any) {
    this.active = index;
    this.show = true;
    if (index === 0) {
      this.getOneData(this.popId);
    } else if (index === 1) {
      this.getTwoData(this.popId);
    } else if (index === 2) {
      this.getThreeData(this.popId);
    } else if (index === 3) {
      this.getFourData(this.popId);
    } else if (index === 4) {
      this.getFiveData(this.popId);
    } else if (index === 5) {
      this.recordList = [];
      this.recordList1 = [];
      this.getSixData(this.popId);
    } else if (index === 6) {
      this.getSevenData(this.popId);
    }
  }
  resultPickersonConfirm(val: any) {
    this.resultPicker = false;
    if (this.myChart1) {
      (this.myChart1 as any).dispose();
      this.myChart1 = null;
    }
    if (this.myChart2) {
      (this.myChart2 as any).dispose();
      this.myChart2 = null;
    }
    setTimeout(() => {
      api
        .getList(this.$store.state.project.projectinfoDetail.id)
        .then((res: any) => {
          var newArray = res.data.filter((item: any) => {
            if (item.id === val.id) {
              return true;
            }
          });
          this.selectList = newArray;
          this.popId = newArray[0].id;
          this.getRecordGroup(newArray[0].id);
          this.getAlarmCountByDays(newArray[0].id);
        });
    }, 500);
  }
  getSelectList() {
    api
      .getList(this.$store.state.project.projectinfoDetail.id)
      .then((res: any) => {
        res.data.map((item: any) => {
          this.resultPickerscolumns.push({
            name: item.name,
            id: item.id,
          });
        });

        this.selectList = res.data;
        if (this.selectList.length > 0) {
          this.getRecordGroup(this.selectList[0].id);
          this.getAlarmCountByDays(this.selectList[0].id);
          this.popId = this.selectList[0].id;
        } else {
          this.initChartOne([{ value: 0, name: "暂无问题" }], 0);
          this.flag = false;
        }
      });
  }
  getPointCount() {
    api
      .getPointCount(this.$store.state.project.projectinfoDetail.id)
      .then((res: any) => {
        this.programTotal = res.data.programTotal;
        this.pointTotal = res.data.pointTotal;
        this.alarmNum = res.data.alarmNum;
        this.warnNum = res.data.warnNum;
      });
  }
  getRecordGroup(id: any) {
    api
      .getRecordGroup({
        projectId: this.$store.state.project.projectinfoDetail.id,
        programId: id,
      })
      .then((res: any) => {
        let sum = 0;
        if (res.data.length == 0) {
          this.recordGroup = [{ value: 0, name: "暂无问题" }];
          this.initChartOne(this.recordGroup, sum);
        } else {
          res.data.map((item: any) => {
            sum += parseInt(item.count);
          });
          res.data.map((item: any, index: any) => {
            this.recordGroup[index] = {
              value: item.count,
              name: this.pointType[item.pointType - 1],
            };
          });
          this.initChartOne(this.recordGroup, sum);
        }
      });
  }
  initChartOne(arr: any, sum: any) {
    var echarts = require("echarts");
    this.myChart1 = echarts.init(document.getElementById("chart-1"), "light");
    // 指定图表的配置项和数据
    var option = {
      // 提示框
      tooltip: {
        show: true,
        // formatter: '{b} </br> 销量{c}件 </br> 占比{d}%' // 提示框显示内容,此处{b}表示各数据项名称，此项配置为默认显示项，{c}表示数据项的值，默认不显示，({d}%)表示数据项项占比，默认不显示。
        // formatter: '{b} </br> 问题数量{c}个 </br>'
        formatter: "告警统计 <br/>{b} : {c}个 <br/>占比{d}%",
      },
      title: {
        text: "累计问题",
        left: "41%",
        top: "50%",
        textStyle: {
          color: "#828895",
          fontSize: 14,
          align: "center",
        },
      },
      grid: {
        width: "83%",
        height: "65%",
      },
      graphic: {
        type: "text",
        left: "center",
        top: "40%",
        style: {
          text: sum,
          textAlign: "center",
          fill: "#828895",
          fontSize: 20,
          fontWeight: 700,
        },
      },

      // 系列列表
      series: [
        {
          name: "圆环图系列名称", // 系列名称
          type: "pie", // 系列类型
          center: ["50%", "50%"], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: ["45%", "70%"], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
          hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]// 圆环图的颜色
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
            },
          },
          data: arr, // 系列中的数据内容数组。
        },
      ],
    };
    (this.myChart1 as any).setOption(option);
  }
  getAlarmCountByDays(id: any) {
    api
      .getAlarmCountByDays({
        projectId: this.$store.state.project.projectinfoDetail.id,
        programId: id,
      })
      .then((res: any) => {
        const xData = res.data.map((item: any) => item.month);
        const alarmCount = res.data.map((item: any) => item.alarmCount);
        const warnCount = res.data.map((item: any) => item.warnCount);
        this.initChartTwo(alarmCount, warnCount, xData);
      });
  }
  initChartTwo(alarmCount: any, warnCount: any, xData: any) {
    var echarts = require("echarts");
    this.myChart2 = echarts.init(document.getElementById("chart-2"));

    (this.myChart2 as any).setOption({
      tooltip: {
        trigger: "axis",
      },

      legend: {
        data: ["告警数", "预警数"],
        top: "5%",
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        height: "70%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        data: xData,
        axisLabel: {
          interval: 0,
          rotate: 40,
          fontSize: 10,
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "告警数",
          type: "line",
          stack: "Total",
          data: alarmCount,
        },
        {
          name: "预警数",
          type: "line",
          stack: "Total",
          data: warnCount,
        },
      ],
    });
  }
  getOneData(id: any) {
    api
      .getOneNewList({
        projectId: this.$store.state.project.projectinfoDetail.id,
        programId: id,
      })
      .then((res: any) => {
        this.recordList = res.data;
      });
  }
  getTwoData(id: any) {
    api
      .getTwoNewList({
        projectId: this.$store.state.project.projectinfoDetail.id,
        programId: id,
      })
      .then((res: any) => {
        this.recordList = res.data;
      });
  }
  getThreeData(id: any) {
    api
      .getThreeNewList({
        projectId: this.$store.state.project.projectinfoDetail.id,
        programId: id,
      })
      .then((res: any) => {
        this.recordList1 = res.data;
      });
  }
  getFourData(id: any) {
    api
      .getFourNewList({
        projectId: this.$store.state.project.projectinfoDetail.id,
        programId: id,
      })
      .then((res: any) => {
        this.recordList1 = res.data;
      });
  }
  getFiveData(id: any) {
    api
      .getFiveNewList({
        projectId: this.$store.state.project.projectinfoDetail.id,
        programId: id,
      })
      .then((res: any) => {
        this.recordList1 = res.data;
      });
  }
  getSixData(id: any) {
    api
      .getSixNewList({
        projectId: this.$store.state.project.projectinfoDetail.id,
        programId: id,
      })
      .then((res: any) => {
        this.recordList1 = res.data;
      });
  }
  getSevenData(id: any) {
    api
      .getSevenNewList({
        projectId: this.$store.state.project.projectinfoDetail.id,
        programId: id,
      })
      .then((res: any) => {
        this.recordList1 = res.data;
      });
  }
}
