import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-517af44b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "safedanger-container" }
const _hoisted_2 = { class: "nav-tab" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tongjifenxi = _resolveComponent("tongjifenxi")!
  const _component_anquanjianchadan = _resolveComponent("anquanjianchadan")!
  const _component_yidongxunjian = _resolveComponent("yidongxunjian")!
  const _component_suishoupai = _resolveComponent("suishoupai")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabArray, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass({ currentStyle: index === _ctx.active }),
          onClick: ($event: any) => (_ctx.changeTab(index))
        }, _toDisplayString(item), 11, _hoisted_3))
      }), 128))
    ]),
    (_ctx.active === 0)
      ? (_openBlock(), _createBlock(_component_tongjifenxi, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.active === 1)
      ? (_openBlock(), _createBlock(_component_anquanjianchadan, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.active === 2)
      ? (_openBlock(), _createBlock(_component_yidongxunjian, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.active === 3)
      ? (_openBlock(), _createBlock(_component_suishoupai, { key: 3 }))
      : _createCommentVNode("", true)
  ]))
}