import request from "@/utils/request";

export default {
  // 安全问题分析
  reportBillCountAllCheck(arg: any) {
    return request({
      url: "project/reportBill/countAllCheck",
      method: "GET",
      params: arg,
    });
  },
  // 移动巡检分析
  inspectionWayRecordAnalysis(arg: any) {
    return request({
      url: "project/inspectionWayRecord/analysis",
      method: "GET",
      params: arg,
    });
  },
  // 安全问题趋势
  kanbanSafeRecentBill(arg: any) {
    return request({
      url: "project/kanban/safe/recentBill",
      method: "GET",
      params: arg,
    });
  },
};
