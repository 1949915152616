import request from "@/utils/request";

export default {
  // 获取监测工程数量
  getCountProgram(id: any) {
    return request({
      url: "project/deviceHighFormwork/countRecord/" + id,
      method: "get",
    });
  },
  // 高支模监测动态
  getProgramList(id: any) {
    return request({
      url: "project/deviceHighFormwork/programList/" + id,
      method: "get",
    });
  },
  // 高支模实时监测
  getPointList(data: any) {
    return request({
      url: "project/deviceHighFormwork/recordList",
      method: "post",
      data,
    });
  },
};
