<template>
  <div>
    <div class="imagecontent">
      <div>
        <div class="image1"/>
        <div :style="{transform: 'rotateZ('+imgrotateZ+'deg)'}" class="image2"/>
        <div class="imagetext1">
          <span>{{ propdata.latest == null ? 0:propdata.latest.windSpeed }}</span><span>m/s</span>
        </div>
        <div class="imagetext2">
          <span>风速</span>
        </div>
      </div>
    </div>

    <div class="item">
      <img :src="require('./image/windpower.png')">
      <span style="color: white">风力</span>
      <span style="float: right;color: rgb(250, 173, 5)">{{ propdata.latest == null ? 0:propdata.latest.windForce }}级</span>
    </div>
    <div class="item">
      <img :src="require('./image/winddirection.png')">
      <span style="color: white">风向</span>
      <span style="float: right;color: rgb(250, 173, 5)">{{ propdata.latest == null ? 0:propdata.latest.windDirection }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Wind',
  props: ['propdata'],
  data() {
    return {}
  },
  computed: {
    imgrotateZ() {
      if (this.$props.propdata.latest == null) {
        return 0
      }
      if (this.$props.propdata.latest.windSpeed > 35) {
        return 180
      } else {
        return parseInt(this.$props.propdata.latest.windSpeed / 35 * 180)
      }
    }
  }
}
</script>

<style scoped>
    .imagecontent {
        overflow: hidden;
        background-color: transparent;
        margin-bottom: 0.1rem;
    }

    .imagecontent > div {
        width: 2rem;
        height: 1rem;
        position: relative;
        margin: 0.3rem auto;
        overflow: hidden;
    }

    .image1 {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("./image/wind1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .image2 {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("./image/wind2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transform-origin: bottom center;
    }

    .imagetext1 {
        position: absolute;
        bottom: 0.2rem;
        left: 50%;
        transform: translateX(-50%);
        color: rgb(250, 173, 5);
    }

    .imagetext1 > span:nth-of-type(1) {
        font-size: 0.24rem;
    }

    .imagetext2 {
        position: absolute;
        bottom: 0rem;
        left: 50%;
        transform: translateX(-50%);
        color: white;
    }

    .item {
        background-color: transparent;
        padding: 0.16rem;
        margin-bottom: 0.01rem;
    }

    .item > img {
        width: 0.2rem;
        height: 0.2rem;
        vertical-align: middle;
    }
</style>
