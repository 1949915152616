<template>
  <div>
    <div class="imagecontent">
      <div class="bgimage">
        <div
          :style="{ transform: 'rotateZ(' + (imgrotateZ - 145) + 'deg)' }"
          class="bgpointer"
        />
        <div class="bgimagetext1">PM2.5</div>
        <div class="bgimagetext2">
          {{ propdata.latest == null ? 0 : propdata.latest.pm25 }}
        </div>
      </div>
      <div class="imagecontenttextl">
        <div style="color: #faad05">
          <span style="font-size: 0.2rem">{{
            propdata.latest == null ? 0 : propdata.latest.humidity
          }}</span
          >%
        </div>
        <div>湿度</div>
      </div>
      <div class="imagecontenttextr">
        <div style="color: #faad05">
          <span style="font-size: 0.2rem">{{
            propdata.latest == null ? 0 : propdata.latest.temperature
          }}</span
          >℃
        </div>
        <div>温度</div>
      </div>
    </div>
    <div class="tabs">
      <div>
        <div style="color: #faad05">
          {{ propdata.latest == null ? 0 : propdata.latest.pm10 }}
        </div>
        <div>PM10</div>
      </div>
      <div>
        <div style="color: #faad05">
          {{ propdata.latest == null ? 0 : propdata.latest.tsp }}
        </div>
        <div>TSP</div>
      </div>
    </div>
    <div class="charts">
      <div v-if="propdata.items.length != 0">
        <ve-line
          ref="vlinea"
          :title="{
            text: 'pm2.5',
            left: 'center',
            textStyle: { color: '#fff' },
          }"
          :data="vlinea.chartData"
          :series="vlinea.chartseries"
          :legend-visible="false"
          :grid="vlinea.chartgrid"
          :y-axis="vlinea.chartyAxis"
          :x-axis="vlinec.chartxAxis"
          height="100%"
        />
      </div>
      <div v-else style="text-align: center; line-height: 1rem; color: white">
        未检测到数据
      </div>
    </div>
    <div class="charts">
      <div v-if="propdata.items.length != 0">
        <ve-line
          ref="vlineb"
          :title="{
            text: 'pm10',
            left: 'center',
            textStyle: { color: '#fff' },
          }"
          :data="vlineb.chartData"
          :series="vlineb.chartseries"
          :legend-visible="false"
          :grid="vlineb.chartgrid"
          :y-axis="vlineb.chartyAxis"
          :x-axis="vlinec.chartxAxis"
          height="100%"
        />
      </div>
      <div v-else style="text-align: center; line-height: 1rem; color: white">
        未检测到数据
      </div>
    </div>
    <div class="charts">
      <div v-if="propdata.items.length != 0">
        <ve-line
          ref="vlinec"
          :title="{ text: 'tsp', left: 'center', textStyle: { color: '#fff' } }"
          :data="vlinec.chartData"
          :series="vlinec.chartseries"
          :legend-visible="false"
          :grid="vlinec.chartgrid"
          :y-axis="vlinec.chartyAxis"
          :x-axis="vlinec.chartxAxis"
          height="100%"
        />
      </div>
      <div v-else style="text-align: center; line-height: 1rem; color: white">
        未检测到数据
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import api from "@/api/projectinfo-environmentmanage.ts";

export default class extends Vue {
  @Prop() propdata = {};
  vlinea = {
    chartData: {
      columns: ["日期"],
      rows: [],
    },
    chartgrid: {
      width: "90%",
      height: "70%",
      top: 50,
    },
    chartxAxis: {
      data: [],
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
    },
    chartyAxis: {
      axisTick: {
        show: false,
      },
      axisLine: { lineStyle: { color: "#fff" } },
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      max: function (val) {
        return 100;
      },
    },
    chartseries: [
      {
        name: "维度2",
        type: "line",
        data: [],
        itemStyle: {
          color: "#00b886",
        },
        markLine: {
          data: null,
        },
      },
    ],
  };

  vlineb = {
    chartData: {
      columns: ["日期"],
      rows: [],
    },
    chartgrid: {
      width: "90%",
      height: "70%",
      top: 50,
    },
    chartxAxis: {
      data: [],
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
    },
    chartyAxis: {
      axisTick: {
        show: false,
      },
      axisLine: { lineStyle: { color: "#fff" } },
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      max: function (val) {
        return 200;
      },
    },
    chartseries: [
      {
        name: "维度2",
        type: "line",
        data: [],
        itemStyle: {
          color: "#4c98f8",
        },
        markLine: {
          data: null,
        },
      },
    ],
  };

  vlinec = {
    chartData: {
      columns: ["日期"],
      rows: [],
    },
    chartgrid: {
      width: "90%",
      height: "70%",
      top: 50,
    },
    chartxAxis: {
      data: [],
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
    },
    chartyAxis: {
      axisTick: {
        show: false,
      },
      axisLine: { lineStyle: { color: "#fff" } },
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      max: function (val) {
        return 350;
      },
    },
    chartseries: [
      {
        name: "维度2",
        type: "line",
        data: [],
        itemStyle: {
          color: "#fda156",
        },
        markLine: {
          data: null,
        },
      },
    ],
  };
  get imgrotateZ() {
    if (this.$props.propdata.latest == null) {
      return 0;
    }
    if (this.$props.propdata.latest.pm25 > 250) {
      return 290;
    } else {
      return parseInt((this.$props.propdata.latest.pm25 / 250) * 290);
    }
  }
  @Watch("propdata")
  eee() {
    this.initmap();
  }
  async initmap() {
    const xaxisarr = [];
    const dataarra = [];
    const dataarrb = [];
    const dataarrc = [];

    this.$props.propdata.items.map((item) => {
      xaxisarr.push(item.createDate.substr(11, 5));
      dataarra.push(item.pm25);
      dataarrb.push(item.pm10);
      dataarrc.push(item.tsp);
    });

    this.vlinea.chartxAxis.data = xaxisarr;
    this.vlineb.chartxAxis.data = xaxisarr;
    this.vlinec.chartxAxis.data = xaxisarr;
    this.vlinea.chartseries[0].data = dataarra;
    this.vlineb.chartseries[0].data = dataarrb;
    this.vlinec.chartseries[0].data = dataarrc;

    const envRuleRes = await api.envrulegetEnvRule();
    this.vlinea.chartseries[0].markLine.data = [
      { yAxis: envRuleRes.data.data.pm25 },
    ];
    this.vlineb.chartseries[0].markLine.data = [
      { yAxis: envRuleRes.data.data.pm10 },
    ];
    this.vlinec.chartseries[0].markLine.data = [
      { yAxis: envRuleRes.data.data.tsp },
    ];
  }
}
</script>

<style scoped>
.imagecontent {
  background-color: transparent;
  position: relative;
  padding: 0.2rem;
}

.bgimage {
  background-image: url("./image/clockdial.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 0.2rem 0 #eaf4eb;
  border-radius: 50%;
}

.bgpointer {
  background-image: url("./image/pointer.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.bgimagetext1,
.bgimagetext2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.bgimagetext1 {
  bottom: 0.54rem;
  font-size: 0.14rem;
  color: #999999;
}

.bgimagetext2 {
  bottom: 0.24rem;
  font-size: 0.24rem;
}

.imagecontenttextl > div:nth-of-type(2),
.imagecontenttextr > div:nth-of-type(2) {
  color: white;
}

.imagecontenttextl {
  position: absolute;
  bottom: 0.2rem;
  left: 0.2rem;
  text-align: center;
}

.imagecontenttextr {
  position: absolute;
  bottom: 0.2rem;
  right: 0.2rem;
  text-align: center;
}

.charts > div:nth-of-type(1) {
  background-color: transparent;
  height: 2.6rem;
  margin-top: 0.1rem;
}

.tabs {
  display: flex;
  background-color: transparent;
  padding: 0.1rem;
  margin-top: 0.1rem;
}

.tabs > div {
  flex: 1;
  text-align: center;
}

.tabs > div > div:nth-of-type(1) {
  font-size: 0.24rem;
  margin-bottom: 0.1rem;
}

.tabs > div > div:nth-of-type(2) {
  color: white;
}

.tabs > div:nth-of-type(1) {
  border-right: 0.02rem solid #e9e9e9;
}
</style>
