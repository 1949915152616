import request from "@/utils/request";

export default {
  // 获取看板设备
  cameraDevicegetBigDataInfoByProjectId(params: any) {
    return request({
      url: "/project/cameraDevice/getBigDataInfoByProjectId",
      method: "get",
      params: params,
    });
  },
  // 查询设备是否在线
  hikvisionrunningState(id: any) {
    return request({
      url: "/device-server/hikvision/runningState/" + id,
      method: "get",
    });
  },
  // 获取播放url
  getVsURL(deviceId: any, channel: any, protocol: any) {
    return request({
      url: "/device-server/video/getVsURL",
      method: "get",
      params: { deviceId: deviceId, channel: channel, protocol: protocol },
    });
  },

  // 获取app
  mobileapplicationgetApps(params: any) {
    return request({
      url: "/center/mobile/application/getApps",
      method: "get",
      params: params,
    });
  },
  // 获取app路由
  sysmenugetAppMenus(params: any) {
    return request({
      url: "/center/sys/menu/getAppMenus",
      method: "get",
      params: params,
    });
  },

  // 当日访客统计
  kanbanprojectvisitorCountDay(params: any) {
    return request({
      url: "/project/kanban/project/visitorCountDay",
      method: "get",
      params: params,
    });
  },
  // 当日出勤统计
  kanbanprojectcheckinCountDay(params: any) {
    return request({
      url: "/project/kanban/project/checkinCountDay",
      method: "get",
      params: params,
    });
  },
  // 查询今日车辆
  kanbanprojecttodayCarTotal(params: any) {
    return request({
      url: "/project/kanban/project/todayCarTotal",
      method: "get",
      params: params,
    });
  },
  // 当日在场人数
  kanbanprojectcountOnSite(params: any) {
    return request({
      url: "/project/kanban/project/countOnSite",
      method: "get",
      params: params,
    });
  },
  // 劳务统计
  kanbanprojectstationCount(params: any) {
    return request({
      url: "/project/kanban/project/stationCount",
      method: "get",
      params: params,
    });
  },
  // 进出统计（工人-访客）
  kanbanprojectaccessTotal(params: any) {
    return request({
      url: "/project/kanban/project/accessTotal",
      method: "get",
      params: params,
    });
  },
  // 人员进出
  kanbanprojectaccessRecord(params: any) {
    return request({
      url: "/project/kanban/project/accessRecord",
      method: "get",
      params: params,
    });
  },

  // 进出统计
  kanbanprojectaccessCount(params: any) {
    return request({
      url: "/project/kanban/project/accessCount",
      method: "get",
      params: params,
    });
  },

  // 当日按照参建单位类别的考勤统计
  kanbanprojectcheckinByDeptType(params: any) {
    return request({
      url: "/project/kanban/project/checkinByDeptType",
      method: "get",
      params: params,
    });
  },

  // 访客统计（分月统计）
  kanbanprojectvisitorCount(params: any) {
    return request({
      url: "/project/kanban/project/visitorCount",
      method: "get",
      params: params,
    });
  },
};
