import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18f86ae0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (
        _ctx.$router.push({
          path: '/app/devicemanage',
          query: { projectId: _ctx.reqdata.project.id },
        })
      )),
      class: "picture"
    }, [
      (_ctx.reqdata.project.mapFileUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.reqdata.project.mapFileUrl
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, "该项目暂无平面布置图"))
    ])
  ]))
}