import request from "@/utils/request";

export default {
  //安全检查单列表
  page(data: any) {
    return request({
      url: "project/reportBill/pageBill",
      method: "post",
      data: data,
    });
  },
  //随手拍列表
  dangeranyPhotopage(params: any) {
    return request({
      url: "project/projectAnyPhoto/page",
      method: "get",
      params: params,
    });
  },
  //巡检点列表
  pagePoint(data: any) {
    return request({
      url: "project/inspectionPointRecord/page",
      method: "post",
      data: data,
    });
  },
  //巡检路线列表
  pageWay(data: any) {
    return request({
      url: "project/inspectionWayRecord/page",
      method: "post",
      data: data,
    });
  },
};
