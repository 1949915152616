<template>
  <div class="page">
    <div class="tr1">
      <div class="tr1-1">
        <span>今日访客人数</span>
        <span>今日出勤人数</span>
      </div>
      <div class="tr1-2">
        <span style="font-weight: 800">{{ visitorCountDay }}</span
        >人 <span style="font-weight: 800">{{ checkinCountDay }}</span
        >人
      </div>
    </div>
    <div class="tr2">
      <div class="tr2-1">
        <div style="display: inline-block"></div>
        <span>今日访客车辆</span>
        <div style="display: inline-block"></div>
        <span>今日进出车辆</span>
      </div>
      <div class="tr2-2">
        <span style="font-weight: 800; color: #4a576a">{{
          todayCarTotal ? todayCarTotal.allCarNum : 0
        }}</span
        >人
        <span style="font-weight: 800; color: #4a576a">{{
          todayCarTotal ? todayCarTotal.allCarNum : 0
        }}</span
        >人
      </div>
    </div>
    <div class="tr3">实时在场人数</div>
    <div class="chart-1">
      <div id="countPresent" style="width: 100%; height: 170px" />
    </div>
    <div class="tr4">劳务管理</div>
    <div class="chart-2">
      <div id="countManage" style="width: 112%; height: 170px" />
    </div>
    <div class="tr5">人员进出</div>
    <div class="chart-3">
      <div id="countType" style="width: 100%; height: 170px" />
    </div>
    <div class="tr6">人员进出统计</div>
    <div class="chart-4">
      <div id="countInoutWorker" />
      <span class="jiange"></span>
      <div id="countInoutVisitor" />
      <span class="jiange-1"></span>
      <div id="countWork" />
      <span class="jiange-2"></span>
      <div id="countVisit" />
    </div>
  </div>
</template>

<script>
import api from "@/api/projectinfo-personmagage";
import { parseTime } from "@/utils";
import { Options, Vue, Watch } from "vue-property-decorator";

export default class extends Vue {
  visitorCountDay = 0;
  checkinCountDay = 0;
  todayCarTotal = null;

  countTypeData = null;
  mounted() {
    this.getReqData();
  }
  getReqData() {
    api
      .kanbanprojectvisitorCountDay({
        projectId: this.$store.state.project.projectinfoDetail.id,
        date: parseTime(new Date(), "{y}-{m}-{d}"),
      })
      .then((res) => {
        this.visitorCountDay = res.data;
      });
    api
      .kanbanprojectcheckinCountDay({
        projectId: this.$store.state.project.projectinfoDetail.id,
        date: parseTime(new Date(), "{y}-{m}-{d}"),
      })
      .then((res) => {
        this.checkinCountDay = res.data;
      });
    api
      .kanbanprojecttodayCarTotal({
        projectId: this.$store.state.project.projectinfoDetail.id,
      })
      .then((res) => {
        this.todayCarTotal = res.data;
      });
    api
      .kanbanprojectcountOnSite({
        projectId: this.$store.state.project.projectinfoDetail.id,
        date: parseTime(new Date(), "{y}-{m}-{d}"),
      })
      .then((res) => {
        this.countPresentInit(res.data.total, res.data.onSite);
      });

    api
      .kanbanprojectstationCount({
        projectId: this.$store.state.project.projectinfoDetail.id,
      })
      .then((res) => {
        const data = [];
        const legendname = [];
        res.data.countList.map((item, i) => {
          data.push({
            value: item.count,
            name: item.stationName,
          });
          legendname.push(item.stationName);
        });
        this.countManageInit(data, legendname);
      });

    api
      .kanbanprojectaccessTotal({
        projectId: this.$store.state.project.projectinfoDetail.id,
        date: parseTime(new Date(), "{y}-{m}-{d}"),
      })
      .then((res) => {
        this.countTypeInit(res.data.workerCount, res.data.total);
      });
    api
      .kanbanprojectaccessRecord({
        projectId: this.$store.state.project.projectinfoDetail.id,
        size: 2,
      })
      .then((res) => {
        this.countTypeData = res.data;
      });

    api
      .kanbanprojectaccessCount({
        projectId: this.$store.state.project.projectinfoDetail.id,
      })
      .then((res) => {
        const workerInarr = [];
        workerInarr.push(res.data.workerIn.count1);
        workerInarr.push(res.data.workerIn.count2);
        workerInarr.push(res.data.workerIn.count3);
        workerInarr.push(res.data.workerIn.count4);
        workerInarr.push(res.data.workerIn.count5);
        workerInarr.push(res.data.workerIn.count6);

        const workerOutarr = [];
        workerOutarr.push(res.data.workerOut.count1);
        workerOutarr.push(res.data.workerOut.count2);
        workerOutarr.push(res.data.workerOut.count3);
        workerOutarr.push(res.data.workerOut.count4);
        workerOutarr.push(res.data.workerOut.count5);
        workerOutarr.push(res.data.workerOut.count6);

        this.countInoutWorkerInit(workerInarr, workerOutarr);

        const visitorInarr = [];
        visitorInarr.push(res.data.visitorIn.count1);
        visitorInarr.push(res.data.visitorIn.count2);
        visitorInarr.push(res.data.visitorIn.count3);
        visitorInarr.push(res.data.visitorIn.count4);
        visitorInarr.push(res.data.visitorIn.count5);
        visitorInarr.push(res.data.visitorIn.count6);

        const visitorOutarr = [];
        visitorOutarr.push(res.data.visitorOut.count1);
        visitorOutarr.push(res.data.visitorOut.count2);
        visitorOutarr.push(res.data.visitorOut.count3);
        visitorOutarr.push(res.data.visitorOut.count4);
        visitorOutarr.push(res.data.visitorOut.count5);
        visitorOutarr.push(res.data.visitorOut.count6);
        this.countInoutVisitorInit(visitorInarr, visitorOutarr);
      });

    api
      .kanbanprojectcheckinByDeptType({
        projectId: this.$store.state.project.projectinfoDetail.id,
        date: parseTime(new Date(), "{y}-{m}-{d}"),
      })
      .then((res) => {
        const yarr = new Array(res.data.length).fill(0);
        const xarr = new Array(res.data.length).fill("");
        res.data.map((li, i) => {
          yarr[i] = li.count;
          xarr[i] = li.deptTypeName;
        });
        this.countWorkInit(yarr, xarr);
      });

    api
      .kanbanprojectvisitorCount({
        projectId: this.$store.state.project.projectinfoDetail.id,
        date: parseTime(new Date(), "{y}-{m}-{d}"),
      })
      .then((res) => {
        const datearr = new Array(12).fill("");
        const visitorNumarr = new Array(12).fill(0);
        res.data.map((li, i) => {
          datearr[i] = parseInt(li.month.substr(4, 2)) + "月";
          visitorNumarr[i] = li.visitorCount;
        });
        this.countVisitInit(datearr, visitorNumarr);
      });
  }
  // 实时在场人数
  countPresentInit(total, onSite) {
    // 基于准备好的dom，初始化this.$echarts实例
    var myChart = this.$echarts.init(
      document.getElementById("countPresent"),
      "light"
    );

    // 指定图表的配置项和数据
    var option = {
      title: {
        left: "center",
        text: "实时在场人数",
        textStyle: { color: "#fff" },
      },
      tooltip: {
        trigger: "item",
        formatter: "{a}<br/>{b}：{c}人<br/>占比：{d}%",
      },
      legend: {
        orient: "vertical",
        right: "right",
        top: "15",
        data: ["在场", "不在场"],
        formatter: function (name) {
          return (
            name + ":" + (name === "在场" ? onSite : total - onSite) + "人"
          );
        },
        icon: "circle",
        // textStyle: { color: "red" },
      },
      series: [
        {
          name: "实时在场人数",
          center: ["35%", "50%"],
          type: "pie",
          startAngle: 180, //起始角度
          label: {
            show: true,
          },
          radius: ["40%", "60%"],
          data: [
            { value: onSite, name: "在场" },
            { value: total - onSite, name: "不在场" },
          ],
        },
      ],
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }
  // 人员管理
  countManageInit(data, legendname) {
    // 基于准备好的dom，初始化this.$echarts实例
    var myChart = this.$echarts.init(
      document.getElementById("countManage"),
      "light"
    );

    // 指定图表的配置项和数据
    var option = {
      title: {
        left: "center",
        text: "劳务管理",
        textStyle: { color: "#fff" },
      },
      tooltip: {
        trigger: "item",
        formatter: "{a}<br/>{b}：{c}人<br/>占比：{d}%",
      },
      legend: {
        orient: "vertical",
        right: "right",
        top: "center",
        data: legendname,
        formatter: function (name) {
          return name + ":" + data[legendname.indexOf(name)].value + "人";
        },
        icon: "circle",
        itemGap: 2,
        // textStyle: { color: "#fff" },
      },
      series: [
        {
          name: "劳务管理",
          center: ["35%", "50%"],
          type: "pie",
          radius: ["40%", "60%"],
          data: data,
          startAngle: 180, //起始角度
          label: {
            show: true,
          },
        },
      ],
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }
  // 人员进出
  countTypeInit(workerCount, total) {
    // 基于准备好的dom，初始化this.$echarts实例
    var myChart = this.$echarts.init(
      document.getElementById("countType"),
      "light"
    );

    // 指定图表的配置项和数据
    var option = {
      title: {
        left: "center",
        text: "人员进出",
        textStyle: { color: "#fff" },
      },
      tooltip: {
        trigger: "item",
        formatter: "{a}<br/>{b}：{c}人<br/>占比：{d}%",
      },
      legend: {
        orient: "vertical",
        right: "right",
        top: "center",
        data: ["工地", "访客"],
        formatter: function (name) {
          return (
            name +
            ":" +
            (name === "工地" ? workerCount : total - workerCount) +
            "人"
          );
        },
        icon: "circle",
      },
      series: [
        {
          name: "人员进出",
          center: ["35%", "50%"],
          type: "pie",
          radius: ["40%", "60%"],
          data: [
            { value: workerCount, name: "工地" },
            { value: total - workerCount, name: "访客" },
          ],
        },
      ],
      graphic: [
        {
          type: "text",
          style: {
            text: "今日进出:" + total + "人 ",
            fill: "#fff",
          },
          left: "right",
          top: "30%",
        },
      ],
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }
  // 工地人员进出统计
  countInoutWorkerInit(workerInarr, workerOutarr) {
    // 基于准备好的dom，初始化this.$echarts实例
    var myChart = this.$echarts.init(
      document.getElementById("countInoutWorker"),
      "light"
    );

    // 指定图表的配置项和数据
    var option = {
      title: {
        text: "工地人员",
        left: "10",
        top: "10",
        textStyle: { fontSize: "14", fontWeight: 600, color: "black" },
      },
      grid: {
        top: "80",
        height: "50%",
      },
      tooltip: {
        trigger: "axis",
        formatter: "{b}时<br/>{a0}：{c0}人<br/>{a1}：{c1}人",
      },
      legend: {
        data: [
          {
            name: "进场人数",
          },
          {
            name: "出场人数",
          },
        ],
        left: "right",
        top: "10",
        icon: "rect",
        // textStyle: { color: "#fff" },
      },
      xAxis: [
        {
          name: "时间",
          boundaryGap: true,
          nameTextStyle: {
            padding: [0, 0, 0, -10], // 四个数字分别为上右下左与原位置距离
          },
          data: [
            "0时-4时",
            "4时-8时",
            "8时-12时",
            "12时-16时",
            "16时-20时",
            "20时-24时",
          ],
          axisLine: { lineStyle: { color: "black" } },
          axisLabel: {
            inside: false,
            textStyle: {
              fontSize: "10",
            },
            formatter: function (params) {
              var newParamsName = ""; // 最终拼接成的字符串
              var paramsNameNumber = params.length; // 实际标签的个数
              var provideNumber = 4; // 每行能显示的字的个数
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
              /**
               * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
               */
              // 条件等同于rowNumber>1
              if (paramsNameNumber > provideNumber) {
                /** 循环每一行,p表示行 */
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = ""; // 表示每一次截取的字符串
                  var start = p * provideNumber; // 开始截取的位置
                  var end = start + provideNumber; // 结束截取的位置
                  // 此处特殊处理最后一行的索引值
                  if (p == rowNumber - 1) {
                    // 最后一次不换行
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    // 每一次拼接字符串并换行
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr; // 最终拼成的字符串
                }
              } else {
                // 将旧标签的值赋给新标签
                newParamsName = params;
              }
              // 将最终的字符串返回
              return newParamsName;
            },
          },
        },
      ],
      yAxis: [
        {
          name: "人数",
          type: "value",
          axisLine: { lineStyle: { color: "black" } },
        },
      ],
      series: [
        {
          name: "进场人数",
          type: "bar",
          data: workerInarr,
          barWidth: 12,
        },
        {
          name: "出场人数",
          type: "bar",
          data: workerOutarr,
          barWidth: 12,
        },
      ],
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }
  // 访客人员进出统计
  countInoutVisitorInit(visitorInarr, visitorOutarr) {
    // 基于准备好的dom，初始化this.$echarts实例
    var myChart = this.$echarts.init(
      document.getElementById("countInoutVisitor"),
      "light"
    );

    // 指定图表的配置项和数据
    var option = {
      title: {
        subtext: "访客人员",
        left: "10",
        top: "-10",
        subtextStyle: { fontSize: "14", fontWeight: 600, color: "black" },
      },
      grid: {
        top: "60",
        height: "50%",
      },
      tooltip: {
        trigger: "axis",
        formatter: "{b}时<br/>{a0}：{c0}人<br/>{a1}：{c1}人",
      },
      legend: {
        data: [
          {
            name: "进场人数",
          },
          {
            name: "出场人数",
          },
        ],
        left: "right",
        top: "0",
        icon: "rect",
        // textStyle: { color: "#fff" },
      },
      xAxis: [
        {
          name: "时间",
          boundaryGap: true,
          nameTextStyle: {
            padding: [0, 0, 0, -10], // 四个数字分别为上右下左与原位置距离
          },
          data: [
            "0时-4时",
            "4时-8时",
            "8时-12时",
            "12时-16时",
            "16时-20时",
            "20时-24时",
          ],
          axisLine: { lineStyle: { color: "black" } },
          axisLabel: {
            inside: false,
            textStyle: {
              fontSize: "10",
            },
            formatter: function (params) {
              var newParamsName = ""; // 最终拼接成的字符串
              var paramsNameNumber = params.length; // 实际标签的个数
              var provideNumber = 4; // 每行能显示的字的个数
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
              /**
               * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
               */
              // 条件等同于rowNumber>1
              if (paramsNameNumber > provideNumber) {
                /** 循环每一行,p表示行 */
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = ""; // 表示每一次截取的字符串
                  var start = p * provideNumber; // 开始截取的位置
                  var end = start + provideNumber; // 结束截取的位置
                  // 此处特殊处理最后一行的索引值
                  if (p == rowNumber - 1) {
                    // 最后一次不换行
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    // 每一次拼接字符串并换行
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr; // 最终拼成的字符串
                }
              } else {
                // 将旧标签的值赋给新标签
                newParamsName = params;
              }
              // 将最终的字符串返回
              return newParamsName;
            },
          },
        },
      ],
      yAxis: [
        {
          name: "人数",
          type: "value",
          axisLine: { lineStyle: { color: "black" } },
        },
      ],
      series: [
        {
          name: "进场人数",
          type: "bar",
          barWidth: 12,
          data: visitorInarr,
        },
        {
          name: "出场人数",
          type: "bar",
          barWidth: 12,
          data: visitorOutarr,
        },
      ],
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }
  // 出勤人员
  countWorkInit(yarr, xarr) {
    // 基于准备好的dom，初始化this.$echarts实例
    var myChart = this.$echarts.init(
      document.getElementById("countWork"),
      "light"
    );

    // 指定图表的配置项和数据
    var option = {
      title: {
        text: "出勤人员",
        left: "10",
        top: "10",
        textStyle: { fontSize: "14", fontWeight: 600, color: "black" },
      },
      grid: {
        top: "60",
        height: "50%",
      },
      tooltip: {
        trigger: "axis",
        formatter: "{a}<br/>{b}：{c}人",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: [
          {
            name: "部门统计",
          },
        ],
        left: "right",
        top: "10",
        right: "2",
        icon: "rect",
        // textStyle: { color: "#fff" },
      },
      xAxis: [
        {
          data: xarr,
          axisLabel: {
            rotate: 45,
          },
          // axisLine: { lineStyle: { color: "#fff" } },
        },
      ],
      yAxis: [
        {
          type: "value",
          // axisLine: { lineStyle: { color: "#fff" } },
        },
      ],
      series: [
        {
          name: "部门统计",
          type: "bar",
          data: yarr,
        },
      ],
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }
  // 访客统计
  countVisitInit(datearr, visitorNumarr) {
    // 基于准备好的dom，初始化this.$echarts实例
    var myChart = this.$echarts.init(
      document.getElementById("countVisit"),
      "light"
    );

    // 指定图表的配置项和数据
    var option = {
      title: {
        text: "访客统计",
        left: "10",
        top: "10",
        textStyle: { fontSize: "14", fontWeight: 600, color: "black" },
      },
      grid: {
        top: "60",
        height: "50%",
      },
      tooltip: {
        trigger: "axis",
        formatter: "访客统计<br/>{b}：{c}人",
      },
      xAxis: [
        {
          boundaryGap: false,
          data: datearr,
          // axisLine: { lineStyle: { color: "#fff" } },
        },
      ],
      yAxis: [
        {
          type: "value",
          // axisLine: { lineStyle: { color: "#fff" } },
        },
      ],
      series: [
        {
          name: "进场人数",
          type: "line",
          data: visitorNumarr,
        },
      ],
    };

    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }
}
</script>

<style lang="scss" scoped>
.page {
  .tr1 {
    width: 343px;
    height: 75px;
    margin: 15px auto;
    background-image: url(./image/personBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .tr1-1 {
      height: 50%;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      > span:nth-of-type(1) {
        margin-left: 20px;
      }
      > span:nth-of-type(2) {
        margin-left: 90px;
      }
    }
    .tr1-2 {
      height: 50%;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      > span:nth-of-type(1) {
        margin-left: 30px;
        font-size: 16px;
      }
      > span:nth-of-type(2) {
        font-size: 16px;
        margin-left: 150px;
      }
    }
  }
  .tr2 {
    width: 343px;
    height: 75px;
    margin: 0 auto;
    background-color: white;
    border-radius: 12px;
    .tr2-1 {
      height: 50%;
      font-size: 14px;
      font-weight: 400;
      color: #8893a3;
      display: flex;
      align-items: center;
      position: relative;
      > span:nth-of-type(1) {
        margin-left: 25px;
      }
      > span:nth-of-type(2) {
        margin-left: 90px;
      }
      > div:nth-of-type(1) {
        width: 6.5px;
        height: 6.5px;
        background-color: #1d5a93;
        position: absolute;
        left: 15px;
        border-radius: 3.25px;
      }
      > div:nth-of-type(2) {
        width: 6.5px;
        height: 6.5px;
        background-color: #1d5a93;
        position: absolute;
        left: 190px;
        border-radius: 3.25px;
      }
    }
    .tr2-2 {
      height: 50%;
      font-size: 14px;
      font-weight: 400;
      color: #8893a3;
      display: flex;
      align-items: center;
      > span:nth-of-type(1) {
        font-size: 16px;
        margin-left: 30px;
      }
      > span:nth-of-type(2) {
        margin-left: 150px;
        font-size: 16px;
      }
    }
  }
  .tr3 {
    font-size: 16px;
    font-weight: 400;
    color: #323433;
    margin: 15px 0px 15px 18px;
  }
  .chart-1 {
    width: 343px;
    height: 160px;
    background-color: white;
    margin: 0 auto;
    border-radius: 10px;
  }
  .tr4 {
    font-size: 16px;
    font-weight: 400;
    color: #323433;
    margin: 15px 0px 15px 18px;
  }
  .chart-2 {
    width: 343px;
    height: 200px;
    background-color: white;
    margin: 0 auto;
    border-radius: 10px;
    overflow: auto;
  }
  .tr5 {
    font-size: 16px;
    font-weight: 400;
    color: #323433;
    margin: 15px 0px 15px 18px;
  }
  .chart-3 {
    width: 343px;
    height: 160px;
    background-color: white;
    margin: 0 auto;
    border-radius: 10px;
  }
  .tr6 {
    font-size: 16px;
    font-weight: 400;
    color: #323433;
    margin: 15px 0px 15px 18px;
  }
  .chart-4 {
    width: 343px;
    height: 980px;
    background-color: white;
    margin: 0 auto;
    border-radius: 10px;
  }
}
#countInoutWorker {
  width: 100%;
  height: 220px;
}
#countInoutVisitor {
  width: 100%;
  height: 220px;
  margin-top: 20px;
}
#countWork {
  width: 100%;
  height: 220px;
  margin-top: 10px;
}
.jiange {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 20px;
}
.jiange-1 {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
.jiange-2 {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 20px;
}
#countVisit {
  width: 100%;
  height: 220px;
  margin-top: 10px;
}
</style>
