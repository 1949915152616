
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/projectinfo-jianchadan";

@Options({})
export default class extends Vue {
  private tabList = ["巡检点", "巡检路线"];
  private activeKey = 0;
  private refreshing = false;
  private loading = false;
  private finished = false;
  private xunJianPointList: any[] = [];
  private loading1 = false;
  private finished1 = false;
  private xunJianWayList: any[] = [];

  protected sidebarchange(val: any) {
    this.refreshing = true;
    this.activeKey = val;
    if (this.activeKey === 0) {
      this.onRefresh();
    } else {
      this.onRefresh1();
    }
  }

  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.xunJianPointList = [];
        this.refreshing = false;
      }

      api
        .pagePoint({
          pageSize: 10,
          pageNum: parseInt(this.xunJianPointList.length / 10 + "") + 1,
          projectId: this.$store.state.project.projectinfoDetail.id,
        })
        .then((res: any) => {
          this.xunJianPointList = [...this.xunJianPointList, ...res.data.records];
          this.loading = false;

          if (this.xunJianPointList.length >= res.data.total) {
            this.finished = true;
          }
        });
    }, 1000);
  }

  onLoad1() {
    setTimeout(() => {
      if (this.refreshing) {
        this.xunJianWayList = [];
        this.refreshing = false;
      }

      api
        .pageWay({
          pageSize: 10,
          pageNum: parseInt(this.xunJianWayList.length / 10 + "") + 1,
          projectId: this.$store.state.project.projectinfoDetail.id,
        })
        .then((res: any) => {
          this.xunJianWayList = [
            ...this.xunJianWayList,
            ...res.data.records,
          ];
          this.loading1 = false;

          if (this.xunJianWayList.length >= res.data.total) {
            this.finished1 = true;
          }
        });
    }, 1000);
  }

  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }

  onRefresh1() {
    this.finished1 = false;
    this.loading1 = true;
    this.onLoad1();
  }
}
