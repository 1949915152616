
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/equipmenttest";

@Options({})
export default class extends Vue {
  private count = {
    num: 0,
    percent: 0,
    warnNum: 0,
  };
  private recordslistOne: any[] = [];
  private recordslistTwo: any[] = [];
  private refreshing = false;

  mounted() {
    this.countToday();
    this.deviceElevatorList();
  }
  countToday() {
    api
      .countToday(this.$store.state.project.projectinfoDetail.id)
      .then((res: any) => {
        if (res.code === 0) {
          this.count.num = res.data.total;
          this.count.percent = res.data.onlinePer;
          this.count.warnNum = res.data.todayWarnNum;
        }
      });
  }
  deviceElevatorList() {
    api
      .deviceElevatorList({
        dirId: this.$store.state.project.projectinfoDetail.id,
        hasLeave: 2,
      })
      .then((res: any) => {
        this.recordslistOne = res.data.one;
        this.recordslistTwo = res.data.two;
      });
  }
  onRefresh() {
    this.recordslistOne = [];
    this.recordslistTwo = [];
    setTimeout(() => {
      this.refreshing = false;
      this.deviceElevatorList();
    }, 1000);
  }
  sjjDetail(item: any) {
    this.$router.push({ path: "/app/sjjdetail" });
    this.$store.commit("project/set_sjjdetail", item);
  }
}
