
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/equipmenttest";

@Options({})
export default class extends Vue {
  private count = {
    num: 0,
    percent: 0,
    warnNum: 0,
  };
  private searchForm = {
    dirId: "",
    pageSize: 10,
    pageNum: 1,
    total: 0,
  };
  // 左侧设备列表
  private towerTable: any[] = [];
  private refreshing = false;

  mounted() {
    this.countToday();
    this.listTower();
  }
  countToday() {
    api
      .deviceTowercountToday(this.$store.state.project.projectinfoDetail.id)
      .then((res: any) => {
        if (res.code === 0) {
          this.count.num = res.data.total;
          this.count.percent = res.data.onlinePer;
          this.count.warnNum = res.data.todayWarnNum;
        }
      });
  }
  listTower() {
    this.searchForm.dirId = this.$store.state.project.projectinfoDetail.id;
    api.page(this.searchForm).then((res: any) => {
      if (res.code === 0) {
        this.towerTable = res.data.records;
      }
    });
  }
  onRefresh() {
    this.towerTable = [];
    setTimeout(() => {
      this.refreshing = false;
      this.listTower();
    }, 1000);
  }
  qzjDetail(item: any) {
    this.$router.push({ path: "/app/qzjdetail" });
    this.$store.commit("project/set_qzjdetail", item);
  }
}
