<template>
  <div class="page">
    <div class="toptime">
      <div
        v-if="selectdevice"
        style="float: left"
        @click="deviceshowPicker = true"
      >
        {{ selectdevice.deviceName }}
      </div>
      <div @click="showPicker = true">
        <span>{{ date }}</span>
        <van-icon name="coupon-o" class="toptimeicon" />
      </div>
    </div>

    <div class="environment">
      <div class="tr1">
        <div>
          <div>
            <img :src="require('./images/pm2.5.png')" />
          </div>
          <div>
            <div>PM2.5</div>
            <div>
              {{ propdata !== null ? propdata.latest.pm25 : "暂无数据" }}
            </div>
          </div>
        </div>
        <div>
          <div>
            <img :src="require('./images/pm10.png')" />
          </div>
          <div>
            <div>PM10</div>
            <div>
              {{ propdata !== null ? propdata.latest.pm10 : "暂无数据" }}
            </div>
          </div>
        </div>
        <div>
          <div>
            <img :src="require('./images/tsp.png')" />
          </div>
          <div>
            <div>TSP</div>
            <div>
              {{ propdata !== null ? propdata.latest.tsp : "暂无数据" }}
            </div>
          </div>
        </div>
      </div>
      <div class="tr2">
        <div>
          <div>
            <img :src="require('./images/temperature.png')" />
          </div>
          <div>
            <div>温度</div>
            <div>
              {{ propdata !== null ? propdata.latest.temperature : "暂无数据" }}
            </div>
          </div>
        </div>
        <div>
          <div>
            <img :src="require('./images/shidu.png')" />
          </div>
          <div>
            <div>湿度</div>
            <div>
              {{
                propdata !== null ? propdata.latest.humidity + "%" : "暂无数据"
              }}
            </div>
          </div>
        </div>
        <div>
          <div>
            <img :src="require('./images/noise.png')" />
          </div>
          <div>
            <div>噪音</div>
            <div>
              {{
                propdata !== null ? propdata.latest.noise + "dB" : "暂无数据"
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="tr3">
        <div>
          <div>
            <img :src="require('./images/wind.png')" />
          </div>
          <div>
            <div>风力</div>
            <div>
              {{
                propdata !== null
                  ? propdata.latest.windForce + "级别"
                  : "暂无数据"
              }}
            </div>
          </div>
        </div>
        <div>
          <div>
            <img :src="require('./images/fengxiang.png')" />
          </div>
          <div>
            <div>风向</div>
            <div>
              {{
                propdata !== null ? propdata.latest.windDirection : "暂无数据"
              }}
            </div>
          </div>
        </div>
        <div>
          <div>
            <img :src="require('./images/noise.png')" />
          </div>
          <div>
            <div>风速</div>
            <div>
              {{
                propdata !== null
                  ? propdata.latest.windSpeed + "m/s"
                  : "暂无数据"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ycjc">扬尘监测</div>
    <div class="yc-chart">
      <div id="main"></div>
    </div>

    <div class="zyjc">噪音监测</div>
    <div class="zy-chart">
      <div id="mainNoise"></div>
    </div>

    <van-popup v-model:show="showPicker" position="bottom">
      <van-datetime-picker
        :value="new Date()"
        :min-date="new Date(2022, 0, 1)"
        type="date"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model:show="deviceshowPicker" position="bottom">
      <van-picker
        :columns="devicelist"
        show-toolbar
        value-key="deviceName"
        @confirm="deviceonConfirm"
        @cancel="deviceshowPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import atmosphere from "./atmosphere";
import noise from "./noise";
import wind from "./wind";
import { parseTime } from "@/utils";
import api from "@/api/projectinfo-environmentmanage.ts";
import { Watch, Vue, Options } from "vue-property-decorator";

@Options({
  components: {
    atmosphere,
    noise,
    wind,
  },
})
export default class extends Vue {
  // components: { atmosphere, noise, wind },
  date = null;
  toptime = new Date();
  showPicker = false;
  propdata = null;
  deviceshowPicker = false;
  selectdevice = null;
  devicelist = [];

  @Watch("toptime")
  watchTime() {
    this.getrequestdata();
  }
  @Watch("selectdevice")
  watchDevice() {
    this.getrequestdata();
  }
  // get computedtoptime() {
  //   return parseTime(new Date(), "{m}月{d}日 星期{a}");
  // }
  mounted() {
    this.date = parseTime(new Date(), "{m}月{d}日 星期{a}");
    this.fRequest();
    this.getrequestdata();
  }
  async fRequest() {
    const devicelistres = await api.mobileenvdevicelist({
      projectId: this.$store.state.project.projectinfoDetail.id,
    });
    this.devicelist = devicelistres.data;
    this.selectdevice = devicelistres.data[0];
  }
  onConfirm(arg) {
    this.toptime = arg;
    this.date = parseTime(new Date(arg), "{m}月{d}日 星期{a}");
    this.showPicker = false;
  }
  deviceonConfirm(arg) {
    this.selectdevice = arg;
    this.deviceshowPicker = false;
  }
  getrequestdata() {
    if (this.selectdevice) {
      api
        .mobileenvitemlist({
          projectId: this.$store.state.project.projectinfoDetail.id,
          createDate: parseTime(this.toptime, "{y}-{m}-{d}"),
          deviceId: this.selectdevice.deviceId,
        })
        .then((res) => {
          var xaxisarr = [];
          var xaxisNoisearr = [];
          var PM25Data = [];
          var PM10Data = [];
          var TspData = [];
          var NoiseData = [];
          res.data.items.map((item) => {
            xaxisarr.push(item.createDate.substr(11, 5));
            PM25Data.push(item.pm25);
            PM10Data.push(item.pm10);
            TspData.push(item.tsp);
            xaxisNoisearr.push(item.createDate.substr(11, 5));
            NoiseData.push(item.noise);
          });
          if (res.data.latest === null) {
            this.propdata = null;
          } else {
            this.propdata = res.data;
          }

          this.environmentCompareInit(xaxisarr, PM25Data, PM10Data, TspData);
          this.noiseInit(xaxisNoisearr, NoiseData);
        });
    }
  }
  environmentCompareInit(xaxisarr, PM25Data, PM10Data, TspData) {
    var myChart = this.$echarts.init(document.getElementById("main"), "light");
    var option;

    option = {
      // title: {
      //   text: "Stacked Line",
      // },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["PM2.5", "PM10", "TSP"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: xaxisarr,
        axisLabel: {
          interval: 0,
          rotate: 40,
          fontSize: 10,
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "PM2.5",
          type: "line",
          stack: "Total",
          data: PM25Data,
        },
        {
          name: "PM10",
          type: "line",
          stack: "Total",
          data: PM10Data,
        },
        {
          name: "TSP",
          type: "line",
          stack: "Total",
          data: TspData,
        },
      ],
    };

    option && myChart.setOption(option);
  }
  noiseInit(xaxisNoisearr, NoiseData) {
    var echarts = require("echarts");
    var myChart = this.$echarts.init(
      document.getElementById("mainNoise"),
      "light"
    );
    var option;

    option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: xaxisNoisearr,
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: NoiseData,
          type: "bar",
          barWidth: 8,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#F79060 ",
              },
              {
                offset: 1,
                color: "#FFC3A6",
              },
            ]),
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }
}
</script>

<style scoped lang="scss">
.page {
  width: 100%;
  .toptime {
    height: 44px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    > div:nth-of-type(1) {
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: #1e2124;
      padding-left: 6px;
    }
    > div:nth-of-type(2) {
      width: 75%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: right;
      font-size: 12px;
      font-weight: 400;
      color: #8893a3;
      .toptimeicon {
        font-size: 14px;
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
  .environment {
    width: 350px;
    height: 206px;
    background-color: white;
    margin: 15px auto;
    border-radius: 5px;
    .tr1,
    .tr2,
    .tr3 {
      width: 100%;
      height: 33.33%;
      display: flex;
      > div {
        width: 33%;
        height: 100%;
        display: flex;
        > div:nth-of-type(1) {
          width: 40%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          img {
            width: 35px;
            height: 35px;
          }
        }
        > div:nth-of-type(2) {
          width: 60%;
          height: 100%;
          display: flex;
          flex-direction: column;
          > div:nth-of-type(1) {
            width: 100%;
            height: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            font-size: 16px;
            font-weight: 400;
            color: #8893a3;
          }
          > div:nth-of-type(2) {
            width: 100%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 400;
            color: #4a576a;
          }
        }
      }
    }
  }
  .ycjc {
    font-size: 16px;
    font-weight: 600;
    color: #323433;
    padding-left: 15px;
  }
  .zyjc {
    font-size: 16px;
    font-weight: 600;
    color: #323433;
    padding-left: 15px;
  }
  .yc-chart {
    width: 350px;
    height: 237px;
    background-color: white;
    border-radius: 5px;
    margin: 15px auto;
  }
  .zy-chart {
    width: 350px;
    height: 237px;
    background-color: white;
    border-radius: 5px;
    margin: 15px auto;
    overflow-x: auto;
  }
}
#main {
  width: 100%;
  height: 237px;
}
#mainNoise {
  width: 110%;
  height: 230px;
}
</style>
