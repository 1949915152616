
import { Options, Vue, Emit, Prop } from "vue-property-decorator";

@Options({
  name: "zuzhijiagou",
})
export default class extends Vue {
  @Prop() private reqdata!: string;
  private activeNames = [];
  private projectImages: string[] = [
    "jianli.png",
    "jianshe.png",
    "sheji.png",
    "kancha.png",
    "shigong.png",
    "daijian.png",
    "zhuanye.png",
    "jianli.png",
    "jianshe.png",
    "sheji.png",
    "kancha.png",
    "shigong.png",
    "daijian.png",
    "zhuanye.png",
  ];
  @Emit()
  btnClick(li: any) {
    this.$router.push({
      path: "/app/projectInfo/deptInfo",
      query: { deptId: li.nodeData.id },
    });
    return false;
  }
}
