<template>
  <div class="page">
    <div id="playerparent">
      <div id="player" style="width: 100%; height: 150px" />
    </div>
    <van-swipe
      :loop="false"
      :width="120"
      style="margin-top: 10px"
      :show-indicators="false"
    >
      <van-swipe-item v-for="(li, i) in videolist" :key="i">
        <div class="videoLi" @click="selectVideo(li)">
          <div class="videoOnline">
            <span :style="{ color: li.online ? '#0ed36a' : 'red' }">●</span>
            {{ li.online ? "在线" : "离线" }}
          </div>
          <img class="videoImg" :src="'http://' + li.screenshot" />
        </div>
        <div class="videoText">{{ li.name }}</div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { Vue } from "vue-property-decorator";
import * as api from "@/api/projectinfo.ts";

export default class extends Vue {
  player = null;
  videolist = null;
  mounted() {
    this.getreqdata();
  }
  selectVideo(video) {
    document
      .getElementById("playerparent")
      .removeChild(document.getElementById("player"));
    document.getElementById("playerparent").innerHTML =
      "<div id='player' style='width: 100%;height: 150px;'></div>";
    this.playfun(video);
  }
  getreqdata() {
    api
      .cameraDevicegetBigDataInfoByProjectId({
        projectId: this.$store.state.project.projectinfoDetail.id,
      })
      .then((res) => {
        api
          .hikvisionrunningState(this.$store.state.project.projectinfoDetail.id)
          .then((resin) => {
            res.data.map((item) => {
              item.online = false;
              resin.data.map((itemin) => {
                if (item.id === itemin) {
                  item.online = true;
                }
              });
            });
            this.videolist = res.data;
            this.playfun(this.videolist[0]);
          });
      });
  }
  playfun(playsrc) {
    this.$nextTick(() => {
      // 开始推流
      api.getVsURL(playsrc.id, playsrc.channel, "hls").then((res) => {
        if (res.code === 0) {
          this.player.dispose();
        }
        const url = res.data.url;

        this.player = new Aliplayer({
          id: "player",
          width: "100%",
          height: "100%",
          autoplay: true,
          preload: false, // 播放器自动加载，目前仅h5可用。
          useH5Prism: true, // 指定使用H5播放器。
          diagnosisButtonVisible: false,
          showBuffer: false,
          enableStashBufferForFlv: false,
          stashInitialSizeForFlv: 0,
          useFlashPrism: false, // 指定使用Flash播放器。
          rtmpBufferTime: 0,
          isLive: true, // 播放内容是否为直播，直播时会禁止用户拖动进度条。
          source: url,
        });
      });
    });
  }
}
</script>

<style lang="scss" scoped>
.videoLi {
  width: 110px;
  height: 100px;
  position: relative;
  margin: 0 auto;
}
.videoOnline {
  font-size: 14px;
  color: #e4e5e6;
  position: absolute;
  right: 0;
}
.videoImg {
  width: 100%;
  height: 100%;
}
.videoText {
  width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  color: #646566;
  background-color: white;
  margin: 0 auto;
}

.page {
  min-height: 100%;
}
</style>
