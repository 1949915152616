import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b04ab958"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_comInfo = _resolveComponent("comInfo")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_comVideo = _resolveComponent("comVideo")!
  const _component_comPerson = _resolveComponent("comPerson")!
  const _component_comWork = _resolveComponent("comWork")!
  const _component_safedanger = _resolveComponent("safedanger")!
  const _component_equipmenttest = _resolveComponent("equipmenttest")!
  const _component_highformwork = _resolveComponent("highformwork")!
  const _component_deepexcavation = _resolveComponent("deepexcavation")!
  const _component_van_tabs = _resolveComponent("van-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      title: _ctx.$route.meta.title,
      "left-arrow": "",
      onClickLeft: _ctx.goBack
    }, null, 8, ["title", "onClickLeft"]),
    _createVNode(_component_van_tabs, {
      active: _ctx.active,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_tab, { title: "项目总况" }, {
          default: _withCtx(() => [
            _createVNode(_component_comInfo)
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "全景监控" }, {
          default: _withCtx(() => [
            _createVNode(_component_comVideo)
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "人员管理" }, {
          default: _withCtx(() => [
            _createVNode(_component_comPerson)
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "绿色施工" }, {
          default: _withCtx(() => [
            _createVNode(_component_comWork)
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "安全隐患" }, {
          default: _withCtx(() => [
            _createVNode(_component_safedanger)
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "设备监测" }, {
          default: _withCtx(() => [
            _createVNode(_component_equipmenttest)
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "高支模监测" }, {
          default: _withCtx(() => [
            _createVNode(_component_highformwork)
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "深基坑监测" }, {
          default: _withCtx(() => [
            _createVNode(_component_deepexcavation)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}