
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/projectinfo-highformwork";

@Options({})
export default class extends Vue {
  private oCountProgram = {};
  private aProgramList = { id: "" };
  private resultPicker = false;
  private recordslist: any[] = [];
  private resultPickerscolumns: any[] = [];
  private refreshing = false;

  mounted() {
    this.fGetCountProgram();
    this.fGetProgramList();
  }

  fGetCountProgram() {
    api
      .getCountProgram(this.$store.state.project.projectinfoDetail.id)
      .then((res: any) => {
        this.oCountProgram = res.data;
      });
  }
  fGetProgramList() {
    api
      .getProgramList(this.$store.state.project.projectinfoDetail.id)
      .then((res: any) => {
        res.data.map((item: any) => {
          this.resultPickerscolumns.push({
            name: item.programName,
            id: item.id,
          });
        });
        if (this.aProgramList.id === "") {
          this.aProgramList = res.data[0];
          if (res.data.length > 0) {
            this.fGetPointList(this.aProgramList.id);
          }
        } else {
          this.fGetPointList(this.aProgramList.id);
        }
      });
  }
  fGetPointList(sName: any) {
    api
      .getPointList({
        dirId: this.$store.state.project.projectinfoDetail.id,
        number: "",
        programId: sName,
      })
      .then((res: any) => {
        this.recordslist = res.data;
      });
  }
  openPopup() {
    this.resultPicker = true;
  }
  resultPickersonConfirm(val: any) {
    api
      .getProgramList(this.$store.state.project.projectinfoDetail.id)
      .then((res: any) => {
        var newArray = res.data.filter((item: any) => {
          if (item.id === val.id) {
            return true;
          }
        });
        this.aProgramList = newArray[0];
      });

    this.resultPicker = false;
    this.recordslist = [];
    this.refreshing = true;
    setTimeout(() => {
      this.refreshing = false;
      this.fGetPointList(val.id);
    }, 1000);
  }
  onRefresh() {
    this.recordslist = [];
    setTimeout(() => {
      this.refreshing = false;
      this.fGetProgramList();
    }, 1000);
  }
}
