
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/equipmenttest";

@Options({})
export default class extends Vue {
  private count = {
    num: 0,
    percent: 0,
    warnNum: 0,
  };
  private refreshing = false;
  private towerTable: any[] = [];
  private searchForm = {
    dirId: "",
    pageSize: 10,
    pageNum: 1,
    total: 0,
    hasLeave: "2",
  };

  mounted() {
    this.searchForm.dirId = this.$store.state.project.projectinfoDetail.id;
    this.countToday();

    api.Xlptpage(this.searchForm).then((res: any) => {
      var newArray: any[] = [];
      newArray = res.data.records;
      api.Xlptlist(this.searchForm).then((res: any) => {
        res.data.map((item: any, index: any) => {
          item.status = newArray[index].status;
        });
        this.towerTable = res.data;
      });
    });
  }

  async countToday() {
    let { data: res } = await api.countXlpt(
      this.$store.state.project.projectinfoDetail.id
    );
    this.count.num = res.total;
    this.count.percent = res.onlineRate.substr(0, 1);
    this.count.warnNum = res.todayWarnNum;
  }
  xlptDetail(item: any) {
    this.$router.push({ path: "/app/xlptdetail" });
    this.$store.commit("project/set_xlptdetail", item);
  }
  onRefresh() {
    this.towerTable = [];
    setTimeout(() => {
      this.refreshing = false;
      this.countToday();
    }, 1000);
  }
}
