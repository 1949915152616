<template>
  <div>
    <div class="image">
      <div>
        <div class="imagetext">
          <div>{{ propdata.latest == null ? 0 : propdata.latest.noise }}</div>
          <div>噪音</div>
          <div>db</div>
        </div>
      </div>
    </div>
    <div class="charts">
      <div v-if="propdata.items.length != 0">
        <ve-histogram
          ref="histogram"
          :title="{
            text: '噪音',
            left: 'center',
            textStyle: { color: '#fff' },
          }"
          :y-axis="chartyAxis"
          :x-axis="chartxAxis"
          :series="chartseries"
          :data="chartData"
          :grid="chartgrid"
          :legend-visible="false"
          height="100%"
        />
      </div>
      <div v-else style="text-align: center; line-height: 1rem; color: white">
        未检测到数据
      </div>
    </div>
  </div>
</template>

<script>
import { Prop, Vue, Watch } from "vue-property-decorator";
import api from "@/api/projectinfo-environmentmanage.ts";
export default class extends Vue {
  @Prop() propdata = {};
  chartData = {
    columns: ["日期"],
    rows: [],
  };
  chartseries = [
    {
      name: "维度2",
      type: "bar",
      data: [],
      barWidth: 8,
      itemStyle: {
        barBorderRadius: [4, 4, 0, 0],
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#11d3ec", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#4454f1", // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
      markLine: {
        data: null,
      },
    },
  ];
  chartxAxis = {
    data: [],
    axisLine: {
      lineStyle: {
        color: "#fff",
      },
    },
  };
  chartyAxis = {
    axisTick: {
      show: false,
    },
    axisLine: { lineStyle: { color: "#fff" } },
    splitLine: {
      lineStyle: {
        type: "dashed",
      },
    },
    max: function (val) {
      return 100;
    },
  };
  chartgrid = {
    width: "90%",
    height: "70%",
    top: 50,
  };
  @Watch("propdata")
  eee() {
    this.initmap();
  }
  mounted() {
    this.initmap();
  }
  async initmap() {
    const xaxisarr = [];
    const dataarr = [];
    this.$props.propdata.items.map((item) => {
      xaxisarr.push(item.createDate.substr(11, 5));
      dataarr.push(item.noise);
    });
    this.chartxAxis.data = xaxisarr;
    this.chartseries[0].data = dataarr;

    const envRuleRes = await api.envrulegetEnvRule();
    this.chartseries[0].markLine.data = [
      { yAxis: envRuleRes.data.noiseDay, lineStyle: { color: "#4454f1" } },
      { yAxis: envRuleRes.data.noiseNight, lineStyle: { color: "#11d3ec" } },
    ];
  }
}
</script>

<style scoped>
.image {
  background-color: transparent;
  overflow: hidden;
}

.image > div {
  width: 1.6rem;
  height: 1.6rem;
  background-image: url("./image/noise.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0.3rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagetext {
  text-align: center;
}

.imagetext > div:nth-of-type(1) {
  color: rgb(250, 173, 5);
  font-size: 0.24rem;
}

.imagetext > div:nth-of-type(2),
.imagetext > div:nth-of-type(3) {
  color: white;
}

.charts > div:nth-of-type(1) {
  background-color: transparent;
  height: 2.6rem;
  margin-top: 0.1rem;
}
</style>
